import React, { useState } from 'react';
import './Header.css';
import { BiMenuAltRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const Header = () => {
  const [menuopen, setMenuOpen] = useState(false);

  const getMenustyle = () => {
    if (document.documentElement.clientWidth <= 800) {
      return { right: menuopen ? null : '+100%' };
    } else {
      return {};
    }
  };
  return (
    <section className="color-bg">
      <div className="h-wrapper">
        <div className="flexCenter paddings innerWidth h-container">
          <img src="./logo2.png" alt="logo" width={100} style={{ backgroundBlendMode: 'multiply' }} />
          <div className="flexCenter h-menu" style={getMenustyle(menuopen)}>
            <Link className="header-black-font" style={{color:'white'}} to="/">
              Home
            </Link>
            <Link className="header-black-font" style={{color:'white'}} to="/projectcompleted">
              Project view
            </Link>
            <Link className="header-black-font" style={{color:'white'}} to="/contactform">
              Contact us
            </Link>
            <Link className="header-black-font" style={{color:'white'}} to="/aboutus">
              About us
            </Link>
            <Link to="/contactform">
              <button className="button"  style={{paddingLeft:'24px',paddingRight:'24px',textAlign:'center',display:'flex',alignItems:'center',justifyContent:'center'}}>Contact</button>
            </Link>
          </div>

          <div className="menu-icon" onClick={() => setMenuOpen((prev) => !prev)}>
            <BiMenuAltRight size={30} color="white" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
