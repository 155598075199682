import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Slider.css';

function BootstrapCarousel() {
  return (
    <Carousel fade fadeDuration={1000} easing="ease-in-out">
      <Carousel.Item>
        <img src="pic 10.jpg" className="d-block w-100 carousel-image" alt="..." />
        <div className="carousel-caption">
          <h3>Transforming Visions into Reality</h3>
          <p>Elevate Possibilities with Premium Aluminum Accessories, Electrical Excellence, Plumbing Perfection, Robust Hardware Tools, and Top-tier Safety Equipment.</p>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <img src="pic 9.jpg" className="d-block w-100 carousel-image" alt="..." />
        <div className="carousel-caption">
          <h3>Quality Essentials Hub</h3>
          <p>Your one-stop destination for all things quality and essential for your projects.</p>
          </div>
      </Carousel.Item>

      <Carousel.Item>
        <img src="pic 7.jpeg" className="d-block w-100 carousel-image" alt="..." />
        <div className="carousel-caption">
          <h3>Bringing Dreams to Life</h3>
          <p>Turning Dreams into Reality with Expertise and Care.</p>
        </div>
      </Carousel.Item>
      {/* Add more Carousel.Item as needed */}
    </Carousel>
  );
}

export default BootstrapCarousel;
