import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
  } from 'react-accessible-accordion';
  
  import 'react-accessible-accordion/dist/fancy-example.css';
  
  import { MdOutlineArrowDropDown } from 'react-icons/md';
  import data from '../../utils/accordion'
  
import './Value.css'

const Value = () => {
  return (
     <section className="V-wrapper" style={{marginTop:'40px',marginBottom:'40px'}} >
        <div className="paddings innerWidth flexCenter v-container">
            {/*left side*/}
            <div className="v-left">
                <div className="image-container">
                    <img src='./pic 7.jpeg' alt=''/>
                </div>
            </div>
            {/*right side*/}
            <div className="flexColStart v-right">
                <span className='orangeText'>Our value</span>
                <span className='primaryText'>Value we Give to you</span>

                <span className='secondaryText'>
                We're here to help. Find the best items in our shop for a comfortable life
                    <br />
                    just like a cozy cottage's charm.
              </span>
                <Accordion className='Accordion'
                allowMultipleExpanded={false}
                preExpanded={[0]}
                >
                    {
                        data.map((item,i)=>{
                         return(
                            <AccordionItem className='accordianitem' key={i} uuid={i}>
                                <AccordionItemHeading>
                                    <AccordionItemButton className='flexCenter accordianbutton'>
                                        <div className="flexCenter icon">{item.icon}</div>
                                        <span className='primaryText'>{item.heading}</span>
                                        <div className="flexCenter icon">
                                            <MdOutlineArrowDropDown size={20}/>
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p className='secondaryText'>{item.detail}</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                         )
                        })
                    }

                </Accordion>

            </div>
        </div>
     </section>
  )
}

export default Value
