import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Contactform.css';

const Contactform = () => {
  const [name, setName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formErrors, setFormErrors] = useState({
    name: '',
    contactNo: '',
    email: '',
    message: '',
  });

  const handleWhatsAppMessage = () => {
    if (validateForm()) {
      const phoneNumber = '971529468886';
      const whatsappMessage = `Name:${name}\nContact No:${contactNo}\nEmail:${email}\nMessage:${message}`;
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;
      window.open(whatsappURL);
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (name.trim() === '') {
      errors.name = 'Name is required';
      isValid = false;
    }

    if (contactNo.trim() === '') {
      errors.contactNo = 'Contact number is required';
      isValid = false;
    }

    if (email.trim() === '') {
      errors.email = 'Email is required';
      isValid = false;
    }

    if (message.trim() === '') {
      errors.message = 'Message is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  return (
    <>
      <Header/>
      <div className="container mt-4">
        <h1 className="mb-4 text-center" style={{ color: '#3498db' }}>
          Contact Us
        </h1>
        <form>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label>
            <input
              type="text"
              className="form-control special-input"
              id="name"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {formErrors.name && <div className="text-danger">{formErrors.name}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="contactNo" className="form-label">Contact No</label>
            <input
              type="text"
              className="form-control special-input"
              id="contactNo"
              placeholder="Enter your contact number"
              value={contactNo}
              onChange={(e) => setContactNo(e.target.value)}
            />
            {formErrors.contactNo && <div className="text-danger">{formErrors.contactNo}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control special-input"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {formErrors.email && <div className="text-danger">{formErrors.email}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Message</label>
            <textarea
              className="form-control special-input"
              id="message"
              rows="3"
              placeholder="Enter your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            {formErrors.message && <div className="text-danger">{formErrors.message}</div>}
          </div>
          <button
            type="button"
            className="btn btn-primary btn-lg btn-block my-3 text-center"
            onClick={handleWhatsAppMessage}
          >
            Send Message
          </button>
        </form>
      </div>

<Footer/>
    </>
  );
};

export default Contactform;
