import { HiShieldCheck } from "react-icons/hi";
import { MdCancel, MdAnalytics } from "react-icons/md";
const data = [
  {
    icon: <HiShieldCheck/>,
    heading: "Prime Deals: Unbeatable Rates in Store!",
    detail:
      "Discover a wonderful selection of special items that will instantly upgrade your living space. Our shop simplifies the process of creating your perfect place. Start exploring and enhancing your environment today!",
  },
  {
    icon: <MdCancel />,
    heading: "Prevent unstable prices",
    detail:
      "Enjoy a seamless shopping experience with us. Say goodbye to price changes and uncertainty. Our store assures you constant reliability and outstanding value, ensuring a worry-free shopping adventure.",
  },
  {
    icon: <MdAnalytics />,
    heading: "Best price on the market",
    detail:
      "Discover unbeatable prices at our shop, where you'll find the best deals on the market. Shop confidently knowing you're getting the most value for your money. Say hello to affordability without compromising on quality.",
  },
];
export default data;