import React from 'react'
import {MdCall} from 'react-icons/md'
import { AiOutlineEllipsis } from 'react-icons/ai';
import { FaVideo } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi'; // Import the email icon from the 'react-icons/hi' library
import { FaWhatsapp } from 'react-icons/fa'; // Import the WhatsApp icon from the 'react-icons/fa' library
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa'; // Import the location icon

import './Contact.css'
const Contact = () => {
  return (
<section className="paddings innerwidth flexCenter c-container">
   {/*left side of contact component*/}
   <div className="flexColStart c-left">
    <span className='orangeText'>Our contact</span>
    <span className='primaryText'>Easy to contact</span>
    <span> We always ready to help by provideing  the best services for you <br />
    we blieve a good blace to live can make your life better
    </span>
    <div className="flexColStart ContactModes">
        {/*first row*/}
        <div className="flexStart row">
            <div className="flexColCenter mode">
                <div className="flexStart">
                <div className="flexCenter icon">
                    <MdCall size={25}/>
                </div>
             <div className="flexColStart detail">
                <span className='primaryText'>Call</span>
                <span className='secondaryText'>+971-56-4419871</span>
             </div>
             </div>
             <a className="flexCenter button" href="tel:+971564419871"> 
             <div >Call Now
  </div></a>

            </div>

            {/*second mode*/}
            <div className="flexColCenter mode">
                <div className="flexStart">
                <div className="flexCenter icon">

                    <FaMapMarkerAlt size={25} />
                </div>
             <div className="flexColStart detail">
                <span className='primaryText'>Location</span>
                <span className='secondaryText' style={{fontSize:'12px'}}>Shop no:2 Industrial-2 Ajman-UAE</span>
             </div>
             </div>
             <a className="flexCenter button" href="https://www.google.com/maps?q=Shop%20no:2,%20Industrial%20-2%20Ajman%20-UAE" target="_blank" rel="noopener noreferrer">
             <div>Location
               </div>
               </a>

            </div>
        </div>

        {/*second row*/}
        <div className="flexStart row">
            <div className="flexColCenter mode">
                <div className="flexStart">
                <div className="flexCenter icon">
                <FaWhatsapp size={25} />

                </div>
             <div className="flexColStart detail">
                <span className='primaryText'>WhatsApp</span>
                <span className='secondaryText'>+971-52-9468886</span>
             </div>
             </div>
             <Link className="flexCenter button" to='/contactform'>
             <div>WhatsApp Now</div>
             </Link>

            </div>

            {/*fourth mode*/}
            <div className="flexColCenter mode">
                <div className="flexStart">
                <div className="flexCenter icon icon-circle">
    <HiOutlineMail size={25} />
</div>

             <div className="flexColStart detail">
                <span className='primaryText'>Email</span>
                <span className='secondaryText'>Alquwa2023@hotmail.com</span>
             </div>
             </div>
             <a className="flexCenter button" style={{color:'blue'}} href='mailto:Alquwa2023@hotmail.com'>
             <div>Email Now</div></a>

            </div>
        </div>
    </div>

   </div>
    {/*Right side of contact component*/}
    <div className="c-right">
       <div className="image-container">
       <img src='./pic 1.jpg' alt=''/>
       </div>
    </div>
</section>
  )
}

export default Contact
