import React from 'react'
import './Getstarted.css'

const Getstarted = () => {
  return (
    <section className="g-wrapper">
        <div className="paddings innerwidth g-container">
            <div className="flexColCenter inner-container">
                <span className='primaryText'>Get start with AL-QUWA Building Material TR</span>
                <span className="secondaryText">
                    subscribe and find super attractive price quote from AL-QUWA Building Material TR
                    <br />
                    Find your Best Building Material TR
                </span>
                <button className="button">
                    <a href="mailto:Alquwa2023@hotmail.com"  style={{color:'white',textDecoration:'none'}}>Get Started</a>
                </button>
            </div>
        </div>
    </section>
  )
}

export default Getstarted
