import React from "react";
import { FaWhatsapp } from "react-icons/fa"; // Import the WhatsApp icon from the 'react-icons/fa' library
import { HiOutlineMail } from 'react-icons/hi'; // Import the email icon from the 'react-icons/hi' library
import { AiOutlineEllipsis } from 'react-icons/ai';
import {MdCall} from 'react-icons/md'
import { FaMapMarkerAlt } from 'react-icons/fa'; // Import the location icon
import {Link} from 'react-router-dom'

import "./Footer.css";
const footer = () => {
  return (
    <section className="f-wrapper bg-color-footer">
      <div className="paddings innerWidth flexCenter f-container">
        {/*left side*/}
        <div className="flexColStart f-left">
          <img src="./logo2.png" width={120} alt=""/>
          <span className="secondaryText" style={{color:'white'}}>
            Our vission to make all people provide best Building  <br />Material TR
          </span>
          <div className="flexCenter icon-rp">
            <FaWhatsapp size={25} color="green" />
            <div className="flexCenter icon-rp">
                    <MdCall size={28} color="white" style={{border:'2px solid white',marginLeft:'10px',borderRadius:'23px',backgroundColor:'#1e4d69'}}/>
                </div>
            <div className="icon-text">
              <span className="ic0n-span">+971-56-4419871</span> <br />
              <span className="ic0n-span2">+971-52-9468886</span>
            </div>
          </div>
          <div className="flexCenter icon-rp" >
          <HiOutlineMail size={25} color="#EA4335"/>
            <div className="icon-text">
              <span className="ic0n-span" style={{color:'grey'}}>Alquwa2023@hotmail.com</span> 
            </div>
          </div>
        </div>
        {/*left side*/}
        <div className="flexColStart f-right">
          <span className="primaryText" style={{color:'orange'}}>Information</span>
          <span className="secondaryText">
          </span>
          <div className="flexCenter icon-rp" style={{marginTop:'20px'}}>
          <FaMapMarkerAlt size={22} color="blue" />
            <div className="icon-text">
              <span className="ic0n-span2" color="grey">Shop no:2, Industrial -2 Ajman -UAE</span>
            </div>
          </div>
          <div className="flexCenter f-menu">
            <span><Link to="/">Project</Link></span>
            <span><Link to="/contactform">Contact us</Link></span>
            <span><Link to='/aboutus'>About us</Link></span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default footer;
