import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './Projectcompleted.css'
const Projectcompletd = () => {
  return (
    <>
    <Header/>
    <div className='Header-projComp'><span className='underline_orange' >Project</span> <span className='underline_red'>Comleted</span></div>
    <hr/>
    <div class="container">
    <div class="row margintop-row">
      <div class="col-md-6 col-mad-center">
       
        <div className='imag-fitting'>
            <img src='pic 9.jpg'/>
        </div>
      </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> Crown plaza Hotel (5 Star) (3B+G+Roof) Business Bay Dubai-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> BUro kilng Architectural Engineering consultants</div>
        <div className='project-text' style={{color:'black',fontWeight:'bold',color:'red'}}><span className='heading-bold-project' style={{color:'black'}}>Client:</span> AL ALi Property Investment</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        At our shop, we supply the Crown Plaza Hotel project in Business Bay, Dubai, UAE, with a curated selection of premium building essentials. From sleek aluminum accessories and cutting-edge electrical items to reliable plumbing solutions, robust hardware tools, and top-notch safety equipment, we contribute to the 5-star elegance envisioned by BUro King Architectural Engineering Consultants. These quality items enhance the luxurious ambiance, functionality, and security of this landmark project for our esteemed client, AL Ali Property Investment.</div>
      </div>
    </div>

    {/*project 2*/}

    <div class="row margintop-row">
    <div class="col-md-6 col-mad-center">
       
       <div className='imag-fitting'>
           <img src='project 2.jpg'/>
       </div>
     </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> API Barsha Residential Tower (B+G+3P+16+Roof) Al Barsha Dubai-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> BUro kilng Architectural Engineering consultants</div>
        <div className='project-text' style={{color:'black',fontWeight:'bold',color:'red'}}><span className='heading-bold-project' style={{color:'black'}}>Client:</span> AL ALi Property Investment</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        The API Barsha Residential Tower in Al Barsha, Dubai, UAE, envisioned by BUro King Architectural Engineering Consultants, harmonizes luxury and function. Crafted with finesse, it features premium aluminum accessories, seamless electrical solutions, optimal plumbing essentials, robust hardware tools, and state-of-the-art safety equipment. This project exemplifies excellence in design, ensuring a secure and sophisticated living environment.</div>
      </div>

    </div>

    {/*project 3*/}

    <div class="row margintop-row">
      <div class="col-md-6 col-mad-center">
       
        <div className='imag-fitting'>
            <img src='pic 8.jpg'/>
        </div>
      </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> Akoyo Damac Qxygen phase 7-cluster N2,M1 & V1 on plot No 9160101, Dubai-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> EC Engineering Consortium Consulting Engineers</div>
        <div className='project-text' style={{color:'black',fontWeight:'bold',color:'red'}}><span className='heading-bold-project' style={{color:'black'}}>Client:</span> Front Line Inverstment Management Company</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        The Akoya DAMAC Oxygen Phase 7, located in Dubai, UAE, and expertly overseen by EC Engineering Consortium Consulting Engineers, redefines modern living. Our shop proudly provides a range of superior aluminum accessories, innovative electrical items, essential plumbing solutions, dependable hardware tools, and top-tier safety equipment. Catering to the project's excellence, these offerings uphold the highest standards, ensuring both functionality and aesthetics for residents.
        </div>
      </div>
    </div>
    

    {/*project 4*/}

    <div class="row margintop-row">


    <div class="col-md-6 col-mad-center">
       
       <div className='imag-fitting'>
           <img src='project 4.jpg'/>
       </div>
     </div>

      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> Commercial Buildings (G-5) 2n0.s AL Shamkha Abudhabi-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> A: Torath international ENGG consultant</div>
        <div className='project-text' style={{color:'black',fontWeight:'bold',color:'red'}}><span className='heading-bold-project' style={{color:'black'}}>Client:</span> MR ALI MUBARAK AL NAYADI</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
TThe Al Shamkha Commercial Buildings (G-5) project in Abu Dhabi, UAE, overseen by Torath International Engineering Consultants, is dedicated to housing a versatile shopping space. Curated by Mr. Ali Mubarak Al Nayadi, the project will feature shops offering aluminum accessories, electrical items, plumbing essentials, hardware tools, and safety equipment, catering to professionals and the public. This venture aims to create a convenient and comprehensive destination for construction and DIY needs.</div>
      </div>

    </div>


    {/*project 5*/}

    <div class="row margintop-row">
      <div class="col-md-6 col-mad-center">
       
        <div className='imag-fitting'>
            <img src='project 5.jpg'/>
        </div>
      </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> G+M+7+R Building, Muhammad Bin Zaid Abudhabi-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> Al Mimar Engineering consultants</div>
        <div className='project-text' style={{color:'black',fontWeight:'bold',color:'red'}}><span className='heading-bold-project' style={{color:'black'}}>Client:</span> MR Muhammad Rashid Mubarak Rashid Al Mansori</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        The Muhammad Bin Zaid G+M+7+R Building, overseen by Al Mimar Engineering Consultants and backed by Mr. Muhammad Rashid Mubarak Rashid Al Mansori, will host shops featuring aluminum accessories, electrical items, plumbing essentials, hardware tools, and safety equipment. This commercial hub aims to provide a comprehensive range of products catering to professionals and the public, contributing to the local economy.</div>
      </div>
    </div>


    {/*project 6*/}

    <div class="row margintop-row">
    <div class="col-md-6 col-mad-center">
       
       <div className='imag-fitting'>
           <img src='project 6.jpg'/>
       </div>
     </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> B+G+1+R Building AL Barsha 3rd Dubai-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> BDPL Gulf Architects Engineering consultants</div>
        <div className='project-text' style={{color:'black',fontWeight:'bold',color:'red',color:'red'}}><span className='heading-bold-project' style={{color:'black'}}>Client:</span> Alia Thomas Greany Awad Al Mehri</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        The AL Barsha 3rd B+G+1+R Building in Dubai, led by BDPL Gulf Architects Engineering Consultants and backed by Alia Thomas Greany Awad Al Mehri, will host shops offering aluminum accessories, electrical items, plumbing essentials, hardware tools, and safety equipment. These shops aim to serve professionals and the public, aligning with the client's vision of a comprehensive commercial space.</div>
      </div>
    </div>

      {/*project 7*/}

      <div class="row margintop-row">
    <div class="col-md-6 col-mad-center">
       
       <div className='imag-fitting'>
           <img src='project 7.jpg'/>
       </div>
     </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> Proposed Private Villa Building, Al Barsha South Dubai-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> BDPL Gulf Architects Engineering consultants</div>
        <div className='project-text' style={{color:'black',fontWeight:'bold',color:'red'}}><span className='heading-bold-project' style={{color:'black'}}>Client:</span> Omran Awad AL Mehri</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        The Proposed Private Villa Building in Al Barsha South, Dubai, led by BDPL Gulf Architects Engineering Consultants and commissioned by Omran Awad AL Mehri, will feature shops offering aluminum accessories, electrical items, plumbing essentials, hardware tools, and safety equipment. These shops will enhance convenience for residents, aligning with the client's vision of a functional and well-equipped living space.</div>
      </div>
    </div>

      {/*project 8*/}

      <div class="row margintop-row">
    <div class="col-md-6 col-mad-center">
       
       <div className='imag-fitting'>
           <img src='project 8.jpg'/>
       </div>
     </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> G+1 Villa +Majlis Block+ Driver Room, Umm Al Sherif,Dubai-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> Extreme Line Engineering consultants</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        The completed project, G+1 Villa + Majlis Block + Driver Room in Umm Al Sherif, Dubai, overseen by Extreme Line Engineering Consultants, introduces a collection of thoughtfully designed shops. These establishments provide a diverse range of indispensable items, encompassing aluminum accessories, electrical products, plumbing essentials, hardware tools, and safety equipment. These meticulously chosen shops cater to the specific requirements of residents, offering a convenient solution for both professionals and DIY enthusiasts in the local community.</div>
      </div>
    </div>

      {/*project 9*/}

      <div class="row margintop-row">
    <div class="col-md-6 col-mad-center">
       
       <div className='imag-fitting'>
           <img src='project 9.jpg'/>
       </div>
     </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> G+1 Villa +Kitchen Block and Driver Block,Al Barsha 2,Dubai-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> Pioneer of Experts Engineering consultants</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        The successfully completed G+1 Villa + Kitchen Block and Driver Block project in Al Barsha 2, Dubai, overseen by Pioneer of Experts Engineering Consultants, encompasses a set of meticulously designed shops. These establishments offer a comprehensive selection of essential items, including aluminum accessories, electrical items, plumbing essentials, hardware tools, and safety equipment. These shops cater to the diverse needs of residents, providing a convenient resource for both professional tradespeople and DIY enthusiasts within the local community.</div>
      </div>
    </div>

      {/*project 10*/}

      <div class="row margintop-row">
    <div class="col-md-6 col-mad-center">
       
       <div className='imag-fitting'>
           <img src='project 10.jpg'/>
       </div>
     </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> G+1 Villa +Kitchen Block,Nad Al Hammar, Dubai-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> BDPL Gulf Architects Engineering consultants</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        The recently completed G+1 Villa + Kitchen Block project in Nad Al Hammar, Dubai, overseen by BDPL Gulf Architects Engineering Consultants, features a collection of thoughtfully curated shops. These shops offer an array of essential products, including aluminum accessories, electrical items, plumbing essentials, hardware tools, and safety equipment. Designed to cater to residents' needs, the shops provide a convenient solution for both professional tradespeople and DIY enthusiasts in the local community.</div>
      </div>
    </div>


      {/*project 11*/}

      <div class="row margintop-row">
    <div class="col-md-6 col-mad-center">
       
       <div className='imag-fitting'>
           <img src='pic 8.jpg'/>
       </div>
     </div>
      <div class="col-md-6">
        <div className='project-text'><span className='heading-bold-project'>Project:</span> Extension of G+1 Villa, Al Tawar 3rd,Dubai-UAE</div>
        <div className='project-text'><span className='heading-bold-project'>Consultant:</span> Neutek Engineering consultants</div>
        <div className='project-text'><span className='heading-bold-project'>Description:</span> 
        The Extension of G+1 Villa project in Al Tawar 3rd, Dubai, benefits from the expertise of Neutek Engineering Consultants. This endeavor envisions a series of shops offering a range of essential items. These include aluminum accessories, electrical items, plumbing essentials, hardware tools, and safety equipment. The diverse selection of shops aims to cater to the practical needs of residents and DIY enthusiasts in the local community.</div>
      </div>
    </div>

  </div>
  <Footer/>
    </>
  )
}

export default Projectcompletd
