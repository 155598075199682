import './App.css';
import Header from './Component/Header/Header'
import Slider from './Component/Slider/Slider';
import Footer from './Component/Footer/Footer';
import Contact from './Component/Contact/Contact';
import Getstarted from './Component/Getstarted/Getstarted';
import Aboutus from './Component/About-Us/Aboutus';
import Contactform from './Component/Contactform/Contactform'
import ProjectDone from './Component/ProjectDone/ProjectDone';
import Projectcompletd from './Component/Projectcomleted/Projectcompletd';
import { BrowserRouter,Route,Routes,Link } from 'react-router-dom';
import Value from './Component/Values/Value';

function App() {
  return (
  <>
<BrowserRouter>
<Routes>
  <Route path='/' element={  <>
    <div className="App">
    <div>  <Header/>
    
    <Slider/></div>
    <ProjectDone/>
    <Value/>
    <Contact/>
    <Getstarted/>
    <div className='color-bg'>
    <Footer/>
    </div>
</div>  

</>}/>
<Route path='contactform' element={<Contactform/>}/>
  <Route path='aboutus' element={<Aboutus/>}/>
  <Route path='projectcompleted' element={<Projectcompletd/>}/>
</Routes>
</BrowserRouter>
</>
);
}

export default App;
