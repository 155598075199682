import React from 'react'
import {Swiper,SwiperSlide,useSwiper} from 'swiper/react'
import "swiper/css"
import './ProjectDone.css'
import data from '../../utils/slider.json'
import { sliderSetting } from '../../utils/comman'
const Residencies = () => {
  return (
   <section className="r-wrapper" style={{marginTop:'80px'}}>
    <div className="paddings innerWidth r-container" style={{margintop:'60px'}}>
        <div className="r-head flexColStart">
            <span className="orangeText">Best Choice</span>
            <span className="primaryText">Available:Best Quality Items</span>
        </div>
        <Swiper {...sliderSetting}>
            <Sliderbutton/>
            {
                data.map((card,i)=>(
                    <SwiperSlide key={i}>
                        <div className="flexColStart r-card">
                            <img src={card.image} alt='home' style={{with:'20px',height:'180px',borderRadius:'20px'}}/>
                            <span className='secondaryText r-price'>
                                <span style={{color:"orange"}}>Available</span>
                            </span>
                            <span className='primaryText'>{card.name}</span>
                            <span className='secondaryText'>{card.detail}</span>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    </div>
    </section>
  )
}

export default Residencies

const Sliderbutton = () => {
    const Swiper=useSwiper();
  return (
    <div className='flexCenter r-button'>
        <button onClick={()=>Swiper.slidePrev()}>&lt;</button>
        <button onClick={()=>Swiper.slideNext()}>&gt;</button>
      
    </div>
  )
}

