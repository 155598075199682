import React from 'react'
import './Aboutus.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Aboutus = () => {
  return (
    <>
<Header className="color-bg"/>
<div className='heading-aboutus'><span style={{color:'orange',paddingRight:'20px' }}>About</span> <span>us</span></div>
<hr/>
<div>
<div className='h1-aboutus'>Al Quwa Building Material LLC: Your One-Stop Shop for Quality Building Supplies</div>
<div className='p1-aboutus'>Welcome to Al Quwa Building Material LLC, your premier destination for a comprehensive range of construction and building supplies in Ajman, UAE. We take immense pride in being a trusted name in the industry, offering an extensive selection of high-quality aluminum accessories, electrical items, plumbing essentials, hardware tools, and safety equipment.</div>
<div className='image-container-about'>
          <div className='image1-about'><img src='pic 3.jpg' alt='Image 1' className='styled-image' /></div>
          <div className='image2-about'><img src='pic 4.jpg' alt='Image 2' className='styled-image' /></div>
          <div className='image3-about'><img src='pic 5.jpg' alt='Image 3' className='styled-image' /></div>
          <div className='image4-about'><img src='pic 9.jpg' alt='Image 4' className='styled-image' /></div>
        </div>
<div className='h1-sub-aboutus'>Our Commitment to Quality and Excellence:</div>
<div className='p2-aboutus'>At Al Quwa Building Material LLC, our mission is to provide top-notch products that cater to the diverse needs of contractors, builders, homeowners, and DIY enthusiasts. With years of experience under our belt, we have developed a keen understanding of the construction industry's demands. This knowledge empowers us to curate a catalog of items that not only meets but exceeds the expectations of our valued customers.</div>
<div className='h1-sub-aboutus'>What Sets Us Apart:</div>
<div className='h1-sub-aboutus-sub'><span className='span-heading'>Wide Range of Products:</span> Whether you're embarking on a major construction project or a simple repair job, we have you covered. From aluminum accessories that add a touch of elegance to your designs, to reliable electrical items that ensure safety and functionality, from plumbing essentials that solve your waterworks challenges, to a diverse array of hardware tools that make tasks easier, and safety equipment that prioritizes well-being, we stock it all.</div>
<div className='h1-sub-aboutus-sub'><span className='span-heading'>Quality Assurance:</span> We understand that the success of any project relies on the durability and performance of the materials used. That's why we source our products from reputable manufacturers known for their commitment to quality. Rest assured, when you shop with us, you're investing in reliability and longevity.</div>
<div className='h1-sub-aboutus-sub'><span className='span-heading'>Customer-Centric Approach:</span>Our customers are at the heart of everything we do. Our knowledgeable and friendly staff are always ready to assist you in finding the right products that align with your project requirements. We believe in forging lasting relationships based on trust and exceptional service.</div>
<div className='h1-aboutus'>Visit Us:</div>
<div className='p1-aboutus'>You can find us conveniently located at Shop 2, Industrial-2, Ajman, UAE. Our physical store is designed to provide you with a hands-on experience of our products, allowing you to make informed decisions</div>
<div className='h1-aboutus'>Contact Us:</div>
<div className='p1-aboutus' style={{marginBottom:'100px'}}>Feel free to reach out to us at <span className='span-bold'>whatsapp</span> <span className='span-bold'>+971-56-4419871</span> and <span className='span-bold'>+971-52-9468886</span> for any inquiries, product details, or assistance. We're here to help you turn your construction visions into reality.

When you choose Al Quwa Building Material LLC, you're choosing a partner dedicated to your success in every construction endeavor. Experience the difference in quality, service, and variety by visiting our store today.




</div>
</div>

<Footer className="color-bg"/>
</>
  )
}

export default Aboutus
